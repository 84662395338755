<template>
    <div class="info">
        <p class="title">
            个人信息
        </p>
        <van-form validate-first @submit="onSubmit">
            <!-- 通过 pattern 进行正则校验 -->
            <van-field
                v-model="userData.nick_name"
                name="nick_name"
                label="昵称"
                placeholder="请输入昵称"
            />
            <!-- 通过 validator 进行函数校验 -->
            <van-field name="radio" label="性别">
                <template #input>
                    <van-radio-group v-model="userData.gender" direction="horizontal">
                        <van-radio :name="1">男</van-radio>
                        <van-radio :name="2">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div style="margin: 100px;">
                <van-button round block type="info" color="linear-gradient(131deg, #38B5F8 0%, #1A7EEF 100%)" native-type="submit">保存</van-button>
            </div>
        </van-form>
        <!-- <button class="btn" @click="$router.push('/')">返回</button> -->
    </div>
</template>

<script>
import { userInfo, updateUserInfo } from '@/util/api'
export default {
    name: 'Info',
    data () {
        return {
            userData: {}
        }
    },
    created () {
        userInfo().then(res => {
            this.userData = res.data
        })
    },
    methods: {
        onSubmit () {
            updateUserInfo(this.userData).then(() => {
                // 要跳转到首页吗？
                this.$router.push('/')
            })
        }
    }
}
</script>

<style scoped>
.btn{
    margin-top: 2rem;
    width: 3rem;
    height: .8rem;
    border: none;
    background: orange;
    color: #fff;
    border-radius: .3rem;
}
.title{
    font-size: .38rem;
    text-align: left;
    color: #333333;
    margin-bottom: .25rem;
    padding-bottom: .2rem;
    border-bottom: 1px solid #ECECEC;
}
.info{
    font-size: .3rem;
    padding: .3rem;
    text-align: center;
}
</style>